<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          Users
        </CCardHeader>
        <CCardBody>
           <!-- <div class="clearfix">
      <button type="button" class="btn btn-secondary float-xl-right" @click="navToNewSymptoms">New User</button>
    </div> -->
          <CDataTable
            hover
            striped
            :items="items"
            :fields="fields"
            :items-per-page="5"
            clickable-rows
            :active-page="activePage"
            @row-clicked="rowClicked"
            :pagination="{ doubleArrows: false, align: 'center'}"
            @page-change="pageChange"
          >
            <template #status="data">
              <td>
                <CBadge :color="getBadge(data.item.status)">
                  {{data.item.status}}
                </CBadge>
              </td>
            </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import usersData from './UsersData'
export default {
  name: 'MyUsers',
  data () {
    return {
      items: usersData,
      fields: [
        { key: 'username', label: 'Name', _classes: 'font-weight-bold',_style: "min-width:200px" },
        { key: 'email',  _classes: 'font-weight-bold',_style: "min-width:200px" },
        { key: 'status' , _classes: 'font-weight-bold',_style: "min-width:200px"},
        { key: 'role' , _classes: 'font-weight-bold',_style: "min-width:200px"},
        { key: 'status', _classes: 'font-weight-bold',_style: "min-width:200px" },
        { key: "show_details",
    label: "Actions",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
      ],
      activePage: 1
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    }
  },
  methods: {
    getBadge (status) {
      switch (status) {
        case 'Active': return 'success'
        case 'Inactive': return 'secondary'
        case 'Pending': return 'warning'
        case 'Banned': return 'danger'
        default: 'primary'
      }
    },
    rowClicked (item, index) {
      this.$router.push({path: `users/${index + 1}`})
    },
    pageChange (val) {
      this.$router.push({ query: { page: val }})
    },

    // navToNewSymptoms(){
    //    this.$router.push({ name: "User", params: { mode: "new" } });
    // }
  }
}
</script>
